import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as shape from 'd3-shape';
import { ShipSailData } from 'src/app/core/models/ship.model';
import { ShipSailDataChange } from 'src/app/core/view-models/ship.view-model';
import { monthMap } from 'src/app/shared/utils/date-transforms/month.map';

@Component({
  selector: 'app-ship-sail-data-graph',
  templateUrl: './ship-sail-data-graph.component.html',
  styleUrls: ['./ship-sail-data-graph.component.scss'],
})
export class ShipSailDataGraphComponent implements OnChanges {
  linearCurve = shape.curveLinear;
  dataset = [{}];

  @Input()
  sailData!: ShipSailData | undefined;

  @Input()
  timeChangeTitle = 'From Previous month';

  @Input()
  eventsChange: number = 0;
  @Input()
  eventsCount: number = 0;
  @Input()
  isCloseEncounterDistanceGraph: boolean = false;

  @Input()
  title = 'Total Events per 1000 NM sailed';
  changesInSailData: ShipSailDataChange[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes['sailData'] && changes['sailData'].currentValue != null) {
      this.setDataSet();
    }
  }

  setDataSet(): void {
    this.dataset = this.isCloseEncounterDistanceGraph
      ? [
          {
            name: 'Ship Sail Data By Congestion Level',
            series: this.sailData!.ceEventsData.map((d, i) => ({
              ...this.sailData!.changesInCeNum[i],
              name: `${monthMap[d.month - 1]} ${d.year}`,
              value: d.avgMinDistance,
            })),
          },
        ]
      : [
          {
            name: 'Ship Sail Data By Congestion Level',
            series: this.sailData!.sailData.map((d, i) => ({
              ...this.sailData!.changesInEventsNum[i],
              name: `${monthMap[d.month - 1]} ${d.year}`,
              value: d.avgNumOfEvents,
            })),
          },
        ];
  }
}
