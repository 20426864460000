import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { WidgetTitleType } from '../../models/WidgetTitle.model';
import { SharedModule } from '../../shared.module';
import { DateFilterKeys } from '../../view-models/dates.view.model';

@Component({
  selector: 'app-widget-title',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    MatProgressBarModule,
    RouterModule,
    MatDividerModule,
  ],
  templateUrl: './widget-title.component.html',
  styleUrls: ['./widget-title.component.scss'],
})
export class WidgetTitleComponent implements OnInit {
  @Input()
  title!: string;
  @Input()
  showModal: boolean = false;
  @Input()
  widgetTitleType: WidgetTitleType = 'basic';
  @Input()
  avgScore$!: Observable<number>;
  @Input()
  secendAvgScore$!: Observable<number>;
  @Input()
  showEventsDayFilterTitle$!: Observable<boolean>;
  @Input()
  selectedDateRange$!: Observable<DateFilterKeys>;
  @Input()
  startDateAvgSeverityScore$!: Observable<number>;
  @Input()
  endDateAvgSeverityScore$!: Observable<number>;
  @Input()
  isShipAvg: boolean = false;

  isTrend: boolean = false;
  modalText: string =
    'This is your fleet average score, considering all ships within your fleet, and their safety score.';

  toggleShowModal() {
    this.showModal = !this.showModal;
  }
  ngOnInit(): void {
    this.isTrend = this.widgetTitleType.includes('trend');
  }
}
