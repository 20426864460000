import { CommonModule } from '@angular/common';
import { Component, ContentChildren, Input, QueryList } from '@angular/core';
import { EventDataComponent } from './event-data/event-data.component';

@Component({
  selector: 'app-event-info',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './event-info.component.html',
  styleUrls: ['./event-info.component.scss'],
})
export class EventInfoComponent {
  @Input() title!: string;

  @ContentChildren(EventDataComponent)
  columns!: QueryList<EventDataComponent>;
}
