import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import dayjs from 'dayjs';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-weather-menu-title',
  templateUrl: './weather-menu-title.component.html',
  standalone: true,
  imports: [MatSlideToggleModule, CommonModule],
  styleUrls: ['./weather-menu-title.component.scss'],
})
export class WeatherMenuTitleComponent {
  @Input() lastUpdateTime$!: BehaviorSubject<Date>;
  @Input() toggleState$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  lastUpdateTime: string = '';

  onToggleChange = (isChecked: boolean) => this.toggleState$.next(isChecked);

  ngOnInit(): void {
    this.lastUpdateTime = this.formatDate(this.lastUpdateTime$.getValue());
    this.lastUpdateTime$.subscribe(
      updateTime => (this.lastUpdateTime = this.formatDate(updateTime))
    );
  }

  private formatDate = (date: Date): string =>
    dayjs(date).format('DD/MM/YYYY HH:mm');
}
