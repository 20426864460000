import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  map,
  Subject,
} from 'rxjs';
import { ShipSafteyScore } from 'src/app/core/models/ship.model';
import { MapboxLegendConfigurations } from 'src/app/mapbox/models/mapbox-legend.models';
import {
  EventMapEntity,
  MapboxSettingOption,
  MapLabel,
  PolygonEntity,
  ShipMapEntity,
} from 'src/app/mapbox/models/mapbox.models';
import { environment } from 'src/environments/environment';
import {
  COMPLIANCE_SETTING_ID,
  GetComplianceSetting,
} from '../map-settings/compliance.setting';
import {
  CONTOUR_SETTING_ID,
  GetCountriesContourSetting,
} from '../map-settings/countries-contour.setting';
import {
  EVENT_AREA_SETTING_ID,
  GetEventAreaSetting,
} from '../map-settings/event-area.setting';
import {
  CreateEventFocusSetting,
  EVENT_FOCUS_SETTING_ID,
} from '../map-settings/event-focus.setting';
import {
  EVENT_SETTING_ID,
  GetEventSetting,
} from '../map-settings/event.setting';
import {
  GetHumiditySetting,
  HUMIDITY_LEGEND_CONFIG,
  HUMIDITY_SETTING_ID,
} from '../map-settings/humidity.setting';
import {
  GetLabelsSetting,
  LABELS_SETTING_ID,
} from '../map-settings/labels.setting';
import { GetLandSetting, LAND_SETTING_ID } from '../map-settings/land.setting';
import {
  GetNoGoSetting,
  NO_GO_SETTING_ID,
} from '../map-settings/no-go.setting';
import {
  GetOceanCurrentsSetting,
  OCEAN_CURRENTS_LEGEND_CONFIG,
  OCEAN_CURRENTS_SETTING_ID,
} from '../map-settings/ocean-currents.setting';
import {
  GetPercipitationSetting,
  PERCIPITATION_LEGEND_CONFIG,
  PERCIPITATION_SETTING_ID,
} from '../map-settings/percipitation.setting';
import {
  GetRTEventSetting,
  RT_EVENT_SETTING_ID,
} from '../map-settings/rt-event.setting';
import {
  CreateShipFocusSetting,
  SHIP_FOCUS_SETTING_ID,
} from '../map-settings/ship-focus.setting';
import {
  GetShipLabelSetting,
  SHIP_LABEL_SETTING_ID,
} from '../map-settings/ship-label.setting';
import {
  CreateShipTrailSetting,
  SHIP_TRAIL_SETTING_ID,
} from '../map-settings/ship-trail.setting';
import { GetShipSetting, SHIP_SETTING_ID } from '../map-settings/ship.setting';
import {
  GetSurfaceSetting,
  SURFACE_SETTING_ID,
} from '../map-settings/surface.setting';
import {
  GetTemperatureSetting,
  TEMPERATURE_LEGEND_CONFIG,
  TEMPERATURE_SETTING_ID,
} from '../map-settings/temperature.setting';
import {
  GetWavesSignificantSetting,
  GetWavesSwellSetting,
  WAVE_LEGEND_CONFIG,
  WAVE_SIGNIFICANT_SETTING_ID,
  WAVE_SWELL_SETTING_ID,
} from '../map-settings/wave.setting';
import {
  GetWindSetting,
  WIND_LEGEND_CONFIG,
  WIND_SETTING_ID,
} from '../map-settings/wind.setting';
import { MenusConfigurations } from '../models/maritime-menus.models';
import {
  ShipCardOuputs,
  ShipLastLocation,
  ShipScreenshot,
} from '../models/ship.models';

const WEATHER_LEGENDS_CONFIGURATIONS: Record<
  string,
  MapboxLegendConfigurations
> = {
  [WAVE_SWELL_SETTING_ID]: WAVE_LEGEND_CONFIG,
  [WAVE_SIGNIFICANT_SETTING_ID]: WAVE_LEGEND_CONFIG,
  [OCEAN_CURRENTS_SETTING_ID]: OCEAN_CURRENTS_LEGEND_CONFIG,
  [WIND_SETTING_ID]: WIND_LEGEND_CONFIG,
  [HUMIDITY_SETTING_ID]: HUMIDITY_LEGEND_CONFIG,
  [PERCIPITATION_SETTING_ID]: PERCIPITATION_LEGEND_CONFIG,
  [TEMPERATURE_SETTING_ID]: TEMPERATURE_LEGEND_CONFIG,
};

@Injectable({
  providedIn: 'root',
})
export class MaritimeSettingsService {
  constructor(private http: HttpClient) {}

  GetWeatherSettingIds(): string[] {
    return [
      WAVE_SWELL_SETTING_ID,
      WAVE_SIGNIFICANT_SETTING_ID,
      WIND_SETTING_ID,
      OCEAN_CURRENTS_SETTING_ID,
      HUMIDITY_SETTING_ID,
      PERCIPITATION_SETTING_ID,
      TEMPERATURE_SETTING_ID,
    ];
  }

  async GetMapLastUpdateTime(): Promise<Date> {
    try {
      const response$ = await this.http.get<{ lastUpdate: string }>(
        `${environment.meteoblue.timeUrl}/${environment.meteoblue.domain}?apikey=${environment.meteoblue.apiKey}`
      );
      return new Date((await firstValueFrom(response$)).lastUpdate);
    } catch (error) {
      console.error(error);
      return new Date(0);
    }
  }

  GetEventEntity(
    events: EventMapEntity[],
    selectedEventId: string
  ): EventMapEntity | undefined {
    return events.find(event => event.id === selectedEventId);
  }

  /* eslint-disable @typescript-eslint/no-empty-function */
  async GetAllMapSettings(
    noGoZones$: BehaviorSubject<PolygonEntity[]>,
    complianceZones$: BehaviorSubject<PolygonEntity[]>,
    events$: BehaviorSubject<EventMapEntity[]>,
    rtEvents$: BehaviorSubject<MapLabel[]>,
    ships$: BehaviorSubject<ShipMapEntity[]>,
    sampleingTime$: BehaviorSubject<string>,
    selectedEvent$: BehaviorSubject<EventMapEntity | undefined>,
    locations$: BehaviorSubject<ShipLastLocation[]>,
    isShipCaptain: boolean,
    outputs: ShipCardOuputs,
    screenshot$: BehaviorSubject<ShipScreenshot | null>,
    focusedShip$: BehaviorSubject<ShipMapEntity | null>,
    shipSaftyScores$: BehaviorSubject<ShipSafteyScore | null>,
    eventZones$: BehaviorSubject<PolygonEntity[]>
  ): Promise<MapboxSettingOption[]> {
    const ship = focusedShip$.getValue();
    const shipLocation$ = new BehaviorSubject<ShipLastLocation | null>(
      ship ? this.GetShipLastLocation(ship) : null
    );
    focusedShip$.subscribe(ship =>
      shipLocation$.next(ship ? this.GetShipLastLocation(ship) : null)
    );
    return [
      {
        id: SURFACE_SETTING_ID,
        setting: GetSurfaceSetting(),
        update$: new Subject(),
        show$: new BehaviorSubject<boolean>(true),
      },
      {
        id: WAVE_SWELL_SETTING_ID,
        setting: GetWavesSwellSetting(sampleingTime$),
        update$: sampleingTime$.pipe(map(() => {})),
        show$: new BehaviorSubject<boolean>(false),
      },
      {
        id: WAVE_SIGNIFICANT_SETTING_ID,
        setting: GetWavesSignificantSetting(sampleingTime$),
        update$: sampleingTime$.pipe(map(() => {})),
        show$: new BehaviorSubject<boolean>(false),
      },
      {
        id: OCEAN_CURRENTS_SETTING_ID,
        setting: GetOceanCurrentsSetting(sampleingTime$),
        update$: sampleingTime$.pipe(map(() => {})),
        show$: new BehaviorSubject<boolean>(false),
      },
      {
        id: LAND_SETTING_ID,
        setting: GetLandSetting(),
        update$: new Subject(),
        show$: new BehaviorSubject<boolean>(false),
      },
      {
        id: WIND_SETTING_ID,
        setting: GetWindSetting(sampleingTime$),
        update$: sampleingTime$.pipe(map(() => {})),
        show$: new BehaviorSubject<boolean>(false),
      },
      {
        id: HUMIDITY_SETTING_ID,
        setting: GetHumiditySetting(sampleingTime$),
        update$: sampleingTime$.pipe(map(() => {})),
        show$: new BehaviorSubject<boolean>(false),
      },
      {
        id: PERCIPITATION_SETTING_ID,
        setting: GetPercipitationSetting(sampleingTime$),
        update$: sampleingTime$.pipe(map(() => {})),
        show$: new BehaviorSubject<boolean>(false),
      },
      {
        id: TEMPERATURE_SETTING_ID,
        setting: GetTemperatureSetting(sampleingTime$),
        update$: sampleingTime$.pipe(map(() => {})),
        show$: new BehaviorSubject<boolean>(false),
      },
      {
        id: CONTOUR_SETTING_ID,
        setting: GetCountriesContourSetting(),
        update$: new Subject(),
        show$: new BehaviorSubject<boolean>(false),
      },
      {
        id: LABELS_SETTING_ID,
        setting: GetLabelsSetting(),
        update$: new Subject(),
        show$: new BehaviorSubject<boolean>(true),
      },
      {
        id: NO_GO_SETTING_ID,
        setting: GetNoGoSetting(noGoZones$),
        update$: noGoZones$.pipe(map(() => {})),
        show$: new BehaviorSubject<boolean>(false),
      },
      {
        id: COMPLIANCE_SETTING_ID,
        setting: GetComplianceSetting(complianceZones$),
        update$: complianceZones$.pipe(map(() => {})),
        show$: new BehaviorSubject<boolean>(false),
      },
      {
        id: SHIP_TRAIL_SETTING_ID,
        setting: await CreateShipTrailSetting(locations$, shipLocation$),
        update$: combineLatest([locations$, shipLocation$]).pipe(map(() => {})),
        show$: new BehaviorSubject<boolean>(false),
      },
      {
        id: EVENT_SETTING_ID,
        setting: GetEventSetting(events$),
        update$: events$.pipe(map(() => {})),
        show$: new BehaviorSubject<boolean>(false),
      },
      {
        id: RT_EVENT_SETTING_ID,
        setting: GetRTEventSetting(rtEvents$),
        update$: rtEvents$.pipe(map(() => {})),
        show$: new BehaviorSubject<boolean>(false),
      },
      {
        id: EVENT_AREA_SETTING_ID,
        setting: GetEventAreaSetting(eventZones$),
        update$: eventZones$.pipe(map(() => {})),
        show$: new BehaviorSubject<boolean>(false),
      },
      {
        id: SHIP_SETTING_ID,
        setting: GetShipSetting(ships$),
        update$: ships$.pipe(map(() => {})),
        show$: new BehaviorSubject<boolean>(true),
      },
      {
        id: SHIP_LABEL_SETTING_ID,
        setting: GetShipLabelSetting(ships$),
        update$: ships$.pipe(map(() => {})),
        show$: new BehaviorSubject<boolean>(true),
      },
      {
        id: SHIP_FOCUS_SETTING_ID,
        setting: await CreateShipFocusSetting(
          isShipCaptain,
          outputs,
          ships$,
          screenshot$,
          focusedShip$,
          shipSaftyScores$,
          events$
        ),
        update$: combineLatest([ships$, focusedShip$]).pipe(map(() => {})),
        show$: new BehaviorSubject<boolean>(false),
      },
      {
        id: EVENT_FOCUS_SETTING_ID,
        setting: await CreateEventFocusSetting(
          events$,
          selectedEvent$,
          focusedShip$
        ),
        update$: combineLatest([events$, selectedEvent$, focusedShip$]).pipe(
          map(() => {})
        ),
        show$: new BehaviorSubject<boolean>(false),
      },
    ];
  }

  GetLegendSettings(weatherOptionId: string): MapboxLegendConfigurations {
    return WEATHER_LEGENDS_CONFIGURATIONS[weatherOptionId];
  }

  GetMenuSettings(
    showLayersMenu: boolean,
    showWeatherMenu: boolean
  ): MenusConfigurations {
    const configurations: MenusConfigurations = {
      menuButtons: [],
      layersFormOptionGroups: [],
      weatherMenuOptions: [],
    };

    if (showWeatherMenu) {
      configurations.menuButtons.push({
        id: 'weather',
        iconUrl: './assets/icons/weather.svg',
      });
      configurations.weatherMenuOptions = [
        WAVE_SWELL_SETTING_ID,
        WAVE_SIGNIFICANT_SETTING_ID,
        WIND_SETTING_ID,
        OCEAN_CURRENTS_SETTING_ID,
        HUMIDITY_SETTING_ID,
        TEMPERATURE_SETTING_ID,
        PERCIPITATION_SETTING_ID,
      ].map(settingId => ({
        id: settingId,
        isChecked: false,
        settingsOptionId: settingId,
      }));
    }
    if (showLayersMenu) {
      configurations.menuButtons.push({
        id: 'layers',
        iconUrl: './assets/icons/layers.svg',
      });
      configurations.layersFormOptionGroups = [
        {
          id: 'Events',
          options: [
            {
              id: 'Events',
              isChecked: true,
              settingsOptionId: EVENT_SETTING_ID,
            },
            {
              id: 'Live Events',
              isChecked: true,
              settingsOptionId: RT_EVENT_SETTING_ID,
            },
          ],
        },
        {
          id: 'Areas of Interest',
          options: [
            {
              id: 'No go Areas',
              isChecked: true,
              settingsOptionId: NO_GO_SETTING_ID,
            },
            {
              id: 'Compliance Areas',
              isChecked: false,
              settingsOptionId: COMPLIANCE_SETTING_ID,
            },
          ],
        },
      ];
    }
    return configurations;
  }

  GetWeatherSamplingTimeString = (): string =>
    dayjs(new Date())
      .subtract(new Date().getUTCHours() % 3, 'hour')
      .utc()
      .format('YYYY-MM-DD[T]HH[:00Z]');

  private GetShipLastLocation(ship: ShipMapEntity): ShipLastLocation {
    const { lat, long, sog, lastConnection } = ship;
    return {
      latitude: lat,
      longitude: long,
      sog: sog ?? 0,
      time: lastConnection?.toISOString() ?? '',
    };
  }
}
