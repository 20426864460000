<div class="container">
  <span class="title">{{ (data$ | async)?.title ?? 'N/A' }}</span>

  <span class="text-with-image">
    <div class="circle acceleration-time"></div>
    <p class="text-small">Acceleration Time</p>
    <p class="text-small semi-bold">
      {{ (data$ | async)?.accelerationMinutes ?? 'N/A' }} min
    </p>
  </span>

  <span class="text-with-image">
    <div class="circle safety-events"></div>
    <p class="text-small">Events With Acceleration</p>
    <p class="text-small semi-bold">
      {{ (data$ | async)?.eventsWithAcceleration ?? 'N/A' }}
    </p>
  </span>
</div>
