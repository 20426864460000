import { Injectable } from '@angular/core';
import { FeatureCollection } from 'geojson';
import { GeoJSONFeature, Map, MapOptions, Popup } from 'mapbox-gl';
import {
  MapboxCustomSetting,
  MapboxEntityConfiguration,
  MapboxSettingOption,
  MapboxSettingType,
  MapboxStyleSetting,
} from '../models/mapbox.models';

@Injectable({
  providedIn: 'root',
})
export class MapboxService {
  UpdateSetting(
    map: Map,
    setting: MapboxCustomSetting | MapboxStyleSetting | null,
    shouldShow: boolean
  ): void {
    if (!setting || setting.type === MapboxSettingType.FromStyle) {
      return;
    }
    if (setting.type === MapboxSettingType.Custom) {
      if (!setting.sources.some(source => map.getSource(source.sourceId))) {
        setting.sources.forEach(source => {
          map.addSource(source.sourceId, source.source);
          if (source.updateFeatures && source.source.type === 'geojson') {
            updateGeoJsonSources(
              source.sourceId,
              source.source.data as FeatureCollection,
              source.updateFeatures
            );
          }
        });
        setting.layers.forEach(layer => {
          map.addLayer(layer);
          if (!shouldShow) {
            map.setLayoutProperty(layer.id, 'visibility', 'none');
          }
        });
      } else {
        setting.sources.forEach(source => {
          if (source.updateFeatures && source.source.type === 'geojson') {
            updateGeoJsonSources(
              source.sourceId,
              source.source.data as FeatureCollection,
              source.updateFeatures
            );
          }
          if (
            source.updateTilesSource &&
            ['raster', 'vector'].includes(source.source.type)
          ) {
            (
              source.source as unknown as { data: { tiles: string[] } }
            ).data.tiles = source.updateTilesSource();
            map.removeSource(source.sourceId);
            map.addSource(source.sourceId, source.source);
          }
        });
      }

      if (setting.updatePopups) {
        setting.popups.forEach(popup => popup.remove());
        setting.popups = setting.updatePopups();
      }
      if (shouldShow && map.getZoom() > (setting.maxZoomForPopups ?? 0)) {
        this.SetPopupsVisibility(setting.popups, map, true);
      } else {
        this.SetPopupsVisibility(setting.popups, map, false);
      }
    }

    function updateGeoJsonSources(
      sourceId: string,
      sourceData: FeatureCollection,
      updateFeatures: () => GeoJSONFeature[]
    ) {
      const features = updateFeatures();
      sourceData.features = features;
      const mapSource = map.getSource(sourceId);
      if (mapSource?.type === 'geojson') {
        mapSource.setData({ type: 'FeatureCollection', features });
      }
    }
  }

  SortMapSettings(map: Map, settingOptions: MapboxSettingOption[]): void {
    const mapLayers = map.getStyle()?.layers ?? [];
    settingOptions.forEach((option, index) => {
      if (index < mapLayers?.length) {
        const setting = option.setting;
        const layerIds =
          setting?.type === MapboxSettingType.Custom
            ? setting.layers.map(layer => layer.id)
            : setting?.layerIds ?? [];
        layerIds.forEach(layerId => {
          if (map.getLayer(layerId)) {
            map.moveLayer(layerId);
          }
        });
      }
    });
  }

  SetSettingVisibility(
    map: Map,
    shouldShow: boolean,
    setting: MapboxCustomSetting | MapboxStyleSetting | null
  ): void {
    if (!setting) {
      return;
    }
    const layerIds: string[] =
      setting.type === MapboxSettingType.FromStyle
        ? setting.layerIds
        : setting.type === MapboxSettingType.Custom
        ? setting.layers.map(layer => layer.id)
        : [];
    const layerVisibility = shouldShow ? 'visible' : 'none';
    layerIds.forEach(layerId =>
      map.setLayoutProperty(layerId, 'visibility', layerVisibility)
    );
    if (setting.type === MapboxSettingType.Custom) {
      if (shouldShow && map.getZoom() > (setting.maxZoomForPopups ?? 0)) {
        this.SetPopupsVisibility(setting.popups, map, true);
      } else {
        this.SetPopupsVisibility(setting.popups, map, false);
      }
    }
  }

  SetAllPopupsVisibility(map: Map, settingOptions: MapboxSettingOption[]) {
    settingOptions.forEach(settingOption => {
      const setting = settingOption.setting;
      if (setting?.type === MapboxSettingType.Custom) {
        if (
          settingOption.show$.getValue() &&
          map.getZoom() > (setting.maxZoomForPopups ?? 0)
        ) {
          this.SetPopupsVisibility(setting.popups, map, true);
        } else {
          this.SetPopupsVisibility(setting.popups, map, false);
        }
      }
    });
  }

  CreateMap(
    mapOptions: MapOptions,
    mapEntityConfigurations: MapboxEntityConfiguration[]
  ): Map {
    const map = new Map(mapOptions);
    mapEntityConfigurations.forEach(mapEntityConfiguration => {
      const entityImage = mapEntityConfiguration.image;
      map.loadImage(entityImage, (error, image) => {
        if (error) console.error(`Error loading image ${image}:`, error);
        else if (image && !map.hasImage(entityImage))
          map.addImage(entityImage, image);
      });
    });
    return map;
  }

  SetPopupsVisibility(popups: Popup[], map: Map, visible: boolean) {
    if (visible) {
      popups.forEach(popup => {
        if (!popup.isOpen()) {
          popup.addTo(map);
        }
        popup.removeClassName('hidden');
      });
    } else {
      popups
        .filter(popup => popup.isOpen())
        .forEach(popup => popup.addClassName('hidden'));
    }
  }
}
