<div class="tooltip" *ngIf="data$ | async as data">
  <div class="container">
    <div class="header">
      <div class="right-side">
        <img class="ship-icon" src="./assets/icons/ship.svg" />
        <span class="ship-count">{{ data.numberOfShips }} Ships</span>
      </div>
      <div class="left-side">{{ data.date | date : 'MMM YYYY' }}</div>
    </div>
    <div class="content">
      <div class="text-with-image">
        <span class="circle fuel-cost"></span>
        <span class="text-small">Fuel Potential Savings</span>
        <span class="text-small semi-bold"
          >{{ data.fuel | number : '1.0-1' }}%</span
        >
      </div>
      <div class="text-with-image">
        <span class="circle safety-events"></span>
        <span class="text-small">Events With Acceleration</span>
        <span class="text-small semi-bold">
          {{ data.eventsWithAcceleration }}
        </span>
      </div>
    </div>
  </div>
</div>
