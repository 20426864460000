import { CommonModule } from '@angular/common';
import { Component, ContentChildren, QueryList } from '@angular/core';
import { InfoColumnComponent } from '../info-column/info-column.component';

@Component({
  selector: 'app-info-row',
  standalone: true,
  imports: [InfoColumnComponent, CommonModule],
  templateUrl: './info-row.component.html',
  styleUrls: ['./info-row.component.scss'],
})
export class InfoRowComponent {
  @ContentChildren(InfoColumnComponent)
  columns!: QueryList<InfoColumnComponent>;
}
