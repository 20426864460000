import { Component, Inject, OnInit } from '@angular/core';
import { DeviceRouterService } from 'dashboard-frontend-library';
import { DestroyRef } from 'projects/orca-lib-main/projects/orca-lib/src/lib/services/destroy-ref.service';
import { catchError, of, switchMap, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { routeExchangeMap } from './app-routing.module';
import { AuthService } from './core/services/auth.service';
import { FeatureFlagService } from './core/services/feature-toggle.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  router: any;
  constructor(
    @Inject(DeviceRouterService)
    private deviceRouterService: DeviceRouterService,
    private authService: AuthService,
    private featureFlag: FeatureFlagService,
    private destroyRef: DestroyRef
  ) {}
  ngOnInit(): void {
    this.checkPermissionsAndNavigate();
  }

  private checkPermissionsAndNavigate(): void {
    this.authService.currentAuthenticatedUser$
      .pipe(
        takeUntil(this.destroyRef.destroy$),
        switchMap(_ =>
          this.featureFlag.getFeatureFlag$('mobile').pipe(
            catchError(() => {
              return of({ hasPermissionForMobile: false });
            })
          )
        )
      )
      .subscribe(hasPermissionForMobile => {
        if (hasPermissionForMobile) {
          this.deviceRouterService.navigateToDeviceApp(
            environment.mobileAppOrigin,
            environment.desktopAppOrigin,
            routeExchangeMap
          );
        }
      });
  }
}
