import { BehaviorSubject } from 'rxjs';
import {
  MapboxLegendConfigurations,
  MapboxLegendTick,
} from 'src/app/mapbox/models/mapbox-legend.models';
import {
  MapboxCustomSetting,
  MapboxSettingType,
} from 'src/app/mapbox/models/mapbox.models';
import { environment } from 'src/environments/environment';

export const OCEAN_CURRENTS_LEGEND_TICKS: MapboxLegendTick[] = [
  { value: 0, color: 'transparent' },
  { value: 0.1, color: '#0C6BEB' },
  { value: 0.2, color: '#5591ED' },
  { value: 0.4, color: '#639DEB' },
  { value: 0.6, color: '#6BA9E9' },
  { value: 0.8, color: '#71B6E5' },
  { value: 1, color: '#75C3E3' },
  { value: 1.2, color: '#76D0E0' },
  { value: 1.4, color: '#75DDDD' },
  { value: 1.6, color: '#96E1D2' },
  { value: 1.9, color: '#ADE6C9' },
  { value: 2.2, color: '#C1EBBE' },
  { value: 2.5, color: '#D2F0B3' },
  { value: 2.8, color: '#E2F5A8' },
  { value: 3.2, color: '#F1FA9B' },
  { value: 3.8, color: '#FFFF8E' },
];

export const OCEAN_CURRENTS_LEGEND_CONFIG: MapboxLegendConfigurations = {
  id: 'ocean-currents',
  unitsName: 'kts',
  legendTicks: OCEAN_CURRENTS_LEGEND_TICKS,
};

export const OCEAN_CURRENTS_SETTING_ID = 'Ocean Currents';

export const GetOceanCurrentsSetting = (
  sampleDateString$: BehaviorSubject<string>
): MapboxCustomSetting => {
  const getTileSource = () => [
    `${
      environment.meteoblue.tilesUrl
    }/vector/RTOFS/${sampleDateString$.getValue()}/oceancurrentColortable~2000~sfc~3hourly~none~contourSteps~-0.05~0.19~0.38~0.54~0.7~0.86~1.08~1.35~1.62~1.89~2.16~2.43~2.7~3.24~3.78_arrows3hourly~2001~sfc~3hourly~none~windArrows/{z}/{x}/{y}?temperatureUnit=C&velocityUnit=kn&lengthUnit=metric&energyUnit=watts&internal=true&apikey=${
      environment.meteoblue.apiKey
    }`,
  ];
  return {
    type: MapboxSettingType.Custom,
    sources: [
      {
        sourceId: 'currents-vector-tile-source',
        source: {
          type: 'vector',
          tiles: getTileSource(),
        },
        updateTilesSource: getTileSource,
      },
    ],
    layers: [
      {
        id: 'currents-height-fill',
        source: 'currents-vector-tile-source',
        'source-layer': 'oceancurrentColortable',
        type: 'fill',
        paint: {
          'fill-antialias': false,
          'fill-opacity': 0.75,
          'fill-color': [
            'interpolate',
            ['linear'],
            ['get', 'minValue'],
            ...OCEAN_CURRENTS_LEGEND_TICKS.flatMap(tick => [
              tick.value,
              tick.color,
            ]),
          ],
        },
        filter: ['all', ['==', '$type', 'Polygon']],
      },
      {
        id: 'currents-height-line',
        type: 'line',
        source: 'currents-vector-tile-source',
        'source-layer': 'arrows3hourly',
        paint: {
          'line-color': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            'black',
            '#848884',
          ],
          'line-width': {
            stops: [
              [0, 1.5],
              [22, 2.5],
            ],
          },
          'line-opacity': 0.8,
        },
        filter: ['all', ['==', '$type', 'LineString']],
      },
    ],
    popups: [],
  };
};
