import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import { Observable, shareReplay, startWith } from 'rxjs';
import {
  DropdownViewModel,
  ViewValue,
} from '../../view-models/dropdown.options.view.model';

@Component({
  selector: 'app-selection-dropdown',
  templateUrl: './selection-dropdown.component.html',
  styleUrls: ['./selection-dropdown.component.scss'],
})
export class SelectionDropdownComponent implements OnInit, OnChanges {
  @Input()
  title!: string;

  @Input()
  options!: DropdownViewModel[];

  @Input()
  selectedValue!: ViewValue<string | string[]>;

  @Input()
  isMultipleChoice = false;

  dropdownForm = this.fb.group<{ dropdownControl: string | string[] }>({
    dropdownControl: [''],
  });

  @Output()
  valueChanged!: Observable<any>;

  @Output()
  valueChange = new EventEmitter<ViewValue<any> | ViewValue<any>[]>();

  @ViewChild('allSelected') private allSelected!: MatOption;

  constructor(private fb: FormBuilder) {}
  ngOnChanges(changes: SimpleChanges): void {
    let defaultSelectedOption: string | string[] = this.selectedValue.value;
    if (this.isMultipleChoice) {
      if (defaultSelectedOption == 'all') {
        defaultSelectedOption = this.getAllValuesOptions();
      }
      this.setSelectedOption(defaultSelectedOption);
    }
  }

  ngOnInit(): void {
    this.valueChanged = this.dropdownForm
      .get('dropdownControl')!
      .valueChanges.pipe(
        startWith(this.dropdownForm.get('dropdownControl')!.value),
        shareReplay(1)
      );
  }

  getSelectLabel() {
    const selectedValues = this.dropdownForm.get('dropdownControl')!.value;
    if (this.allSelected && this.allSelected.selected) {
      return 'All';
    }
    return this.isMultipleChoice && Array.isArray(selectedValues)
      ? (selectedValues || [])
          .map(v => this.options.find(o => o.value === v)?.viewValue)
          .join(', ')
      : selectedValues;
  }

  private getAllValuesOptions(): string[] {
    return [...this.options.map(item => item.value), 'all'];
  }

  clear(): void {
    const values = this.isMultipleChoice
      ? this.getAllValuesOptions()
      : this.options[0].value;
    this.setSelectedOption(values);
    this.emitChangeOptions(values);
  }

  private setSelectedOption(option: string | string[]): void {
    this.dropdownForm.controls.dropdownControl.setValue(
      this.isMultipleChoice ? [...option] : option
    );
  }

  emitChangeOptions(optionsValue: string | string[]): void {
    let value: string | string[];
    if (Array.isArray(optionsValue)) {
      if (optionsValue.includes('all')) value = 'all';
      else
        value = optionsValue
          .map((selectedValue: string) => {
            return this.options.find(option => option.value === selectedValue)!
              .value;
          })
          .filter(value => !!value);
    } else {
      value = this.options.find(option => option.value === optionsValue)!.value;
    }
    this.valueChange.emit({ value });
  }

  togglePerOne() {
    if (this.isMultipleChoice) {
      if (this.allSelected.selected) {
        this.allSelected.deselect();
      } else if (
        this.dropdownForm.controls.dropdownControl.value?.length ==
        this.options.length
      )
        this.allSelected.select();
    }
    this.emitChangeOptions(this.dropdownForm.controls.dropdownControl.value!);
  }

  toggleAllSelection() {
    const options: string[] = this.allSelected.selected
      ? this.getAllValuesOptions()
      : [];
    this.setSelectedOption(options);
    this.emitChangeOptions(options);
  }
}
