import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { FormErrorMatcher } from 'src/app/shared/utils/form-error-matchers/form-error-matcher';
import { isSamePasswordValidator } from 'src/app/shared/utils/form-validators/samePasswordValidator';
import { CoreState } from '../../../store/state/core.state';
import {
  AuthenticationActions,
  AuthenticationSelectors,
} from '../../../store/types';
import { SignupForm } from '../../../view-models/sign-up.view-model';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  formErrorMatcher = new FormErrorMatcher();
  signUpForm = this.fb.group(
    {
      firstname: ['', [Validators.required, Validators.pattern('^[A-Za-z]+$')]],
      lastname: ['', [Validators.required, Validators.pattern('^[A-Za-z]+$')]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      companyName: [''],
      readLegalDoc: [false, [Validators.requiredTrue]],
    },
    { validators: [isSamePasswordValidator()] }
  );

  constructor(private fb: FormBuilder, private store: Store<CoreState>) {}
  async ngOnInit(): Promise<void> {
    const companyName = await firstValueFrom(
      this.store.select(AuthenticationSelectors.selectUsersCompanyName)
    );
    // const company = 'orca-ai';
    this.signUpForm.patchValue({ companyName });
  }

  signup(): void {
    const signupForm: SignupForm = {
      email: this.signUpForm.value.email!,
      password: this.signUpForm.value.password!,
      firstName: this.signUpForm.value.firstname!,
      lastName: this.signUpForm.value.lastname!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      companyName: this.signUpForm.get('companyName')?.value!, //TODO: check this
    };
    this.store.dispatch(AuthenticationActions.signup({ signupForm }));
  }
}
