import { Pipe, PipeTransform } from '@angular/core';
import { Limitation } from '../view-models/event.view.model';

@Pipe({
  name: 'isViolatedLimitation',
  standalone: true,
})
export class IsViolatedLimitationPipe implements PipeTransform {
  transform(value: number, limitation: Limitation): boolean {
    const violatedValueNotInRange = limitation.notInRange
      ? value > limitation.notInRange.min && value < limitation.notInRange.max
      : false;
    const violatedValueInMaxAllowed = limitation.maxAllowed
      ? value > limitation.maxAllowed
      : false;
    const violatedValueInMinAllowed = limitation.minAllowed
      ? value < limitation.minAllowed
      : false;
    const isViolatedLimitation =
      violatedValueNotInRange ||
      violatedValueInMinAllowed ||
      violatedValueInMaxAllowed;
    return isViolatedLimitation;
  }
}
