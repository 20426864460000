import { Timestamp } from '@angular/fire/firestore';
import { DateFilter } from 'src/app/shared/view-models/dates.view.model';
import { ViewValue } from 'src/app/shared/view-models/dropdown.options.view.model';
import { EventSeverityKeys } from 'src/app/shared/view-models/general.event.view.model';
import {
  defaultRange,
  Range,
} from 'src/app/shared/view-models/range.view.model';
import {
  AlertNames,
  ColregClassificationNames,
  CongestionLevelsNames,
  TargetTypeNames,
} from '../models/events-filters.model';

export type EventGroup = 'severity' | 'alert_name' | 'crew_shift';
export type ViewAlertNames = AlertNames[] | 'all';
export type ViewCongestionLevelNames = CongestionLevelsNames[] | 'all';
export type ViewColregClassificationNames = ColregClassificationNames[] | 'all';
export type ViewTargetTypes = TargetTypeNames[] | 'all';

export interface AppliedGeneralFilters {
  readonly shipName: ViewValue<string>;
  readonly severityLevels: EventSeverityKeys[];
  readonly eventType: ViewValue<ViewAlertNames>;
  readonly congestionLevel: ViewValue<ViewCongestionLevelNames>;
  readonly locations: string[];
  readonly ownSog: Range;
  readonly inPort: boolean | null;
  readonly showOnlyRt: boolean | null;
}
export interface AppliedCloseEncounterFilter {
  readonly minDistance: Range;
  readonly colregClassifications: ViewValue<ViewColregClassificationNames>;
  readonly targetSog: Range;
  readonly targetType: ViewValue<ViewTargetTypes>;
}

export interface AppliedUKCFilter {
  readonly minDepth: Range;
}

export interface AppliedHighPitchRollFilter {
  readonly roll: Range;
  readonly pitch: Range;
}

export interface AppliedSpeedDropFilter {
  readonly sogDiff: Range;
}

export interface AppliedHighRotFilter {
  readonly rot: Range;
}

export interface SingleLocation {
  readonly id: string;
  readonly location: string;
}

export interface LocationsViewModel {
  readonly parentLocation: SingleLocation;
  readonly subLocations: SingleLocation[];
}
export interface AppliedEventFilters {
  readonly shipName: ViewValue<string[] | string | 'all'>;
  readonly severityLevels: EventSeverityKeys[];
  readonly eventType: ViewValue<ViewAlertNames>;
  readonly congestionLevel: ViewValue<ViewCongestionLevelNames>;
  readonly locations: string[];
  readonly ownSog: Range;
  readonly inPort: boolean | null;

  readonly minDistance: Range;
  readonly colregClassifications: ViewValue<ViewColregClassificationNames>;
  readonly targetSog: Range;
  readonly targetType: ViewValue<ViewTargetTypes>;
  readonly minDepth: Range;
  readonly roll: Range;
  readonly pitch: Range;
  readonly sogDiff: Range;
  readonly rot: Range;
  readonly showOnlyRt: boolean;
  readonly hasAccelerationTime: boolean | null;
}

export interface AppliedEventUrlFilter extends AppliedEventFilters {
  readonly dateFilter: DateFilter;
}

export type MultipleChoiceEventFilter =
  | 'shipName'
  | 'eventType'
  | 'congestionLevel'
  | 'colregClassifications'
  | 'targetType';

export const defaultAppliedEventFilters: AppliedEventFilters = {
  severityLevels: ['high', 'medium'],
  congestionLevel: { value: 'all' },
  eventType: { value: 'all' },
  locations: [],
  shipName: { value: 'all' },
  ownSog: defaultRange,
  inPort: false,
  colregClassifications: { value: 'all' },
  minDistance: defaultRange,
  targetSog: defaultRange,
  targetType: { value: 'all' },
  pitch: defaultRange,
  roll: defaultRange,
  rot: defaultRange,
  sogDiff: defaultRange,
  minDepth: defaultRange,
  showOnlyRt: false,
  hasAccelerationTime: null,
};

export const defaultAppliedOverviewEventFilters: AppliedEventFilters = {
  ...defaultAppliedEventFilters,
  severityLevels: ['high'],
};

export interface PresetFilterDto {
  readonly presetId: string;
  readonly presetName: string;
  readonly filters: AppliedEventFilters;
  readonly createdAt: Timestamp;
}

export interface PresetFilter {
  readonly presetId: string | null;
  readonly presetName: string;
  readonly filters: AppliedEventFilters;
  readonly createdAt: Date;
}

export const defaultPresetFilter: PresetFilter = {
  presetId: null,
  presetName: 'None',
  filters: { ...defaultAppliedEventFilters },
  createdAt: new Date(),
};
