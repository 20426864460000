import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { SafetyLevelOptions } from 'src/app/core/models/ships-filters.model';
import { CoreState } from 'src/app/core/store/state/core.state';
import { ShipsSelectors } from 'src/app/core/store/types';
import { ShipsBySeverity } from 'src/app/core/view-models/ship.view-model';
import { SortDetails } from 'src/app/shared/components/table/models/sort-details.model';
import { GraphTooltipMapping } from 'src/app/shared/models/graph-data-model';

@Component({
  selector: 'app-ships-risks',
  templateUrl: './ships-risks.component.html',
  styleUrls: ['./ships-risks.component.scss'],
})
export class ShipsRisksComponent implements OnInit {
  colorScheme = [
    { name: 'low', value: 'var(--graph-red)' },
    { name: 'moderate', value: 'var(--graph-yellow)' },
    { name: 'good', value: 'var(--graph-darkseagreen)' },
    { name: 'excellent', value: 'var(--graph-green)' },
  ];
  shipsBySeverityTotalCount: number = 0;
  shipsBySeverityLevel$!: Observable<ShipsBySeverity>;
  shipsBySeverityLevelView$!: Observable<{ name: string; value: number }[]>;

  riskClass = 'risk';
  risksMap: GraphTooltipMapping = {
    excellent: {
      divClass: 'excellent',
      text: 'Excellent',
    },
    good: {
      divClass: 'good',
      text: 'Good',
    },
    moderate: {
      divClass: 'moderate',
      text: 'Moderate',
    },
    low: {
      divClass: 'low',
      text: 'Low',
    },
  };

  dataExists$!: Observable<boolean>;

  constructor(private store: Store<CoreState>, private router: Router) {}

  onPieComponentClick(safetyLevel: SafetyLevelOptions) {
    const sortBySafetyLevel: SortDetails = {
      column: 'safety',
      direction: safetyLevel == 'excellent' ? 'desc' : 'asc',
    };

    this.router.navigate(['/private/fleet'], {
      state: { sortBySafetyLevel: sortBySafetyLevel },
    });
  }

  onCenterPieComponentClick() {
    this.router.navigate(['/private/fleet']);
  }

  ngOnInit(): void {
    this.shipsBySeverityLevel$ = this.store.select(
      ShipsSelectors.selectAvgSeverityLevelsOfShips
    );

    this.shipsBySeverityLevel$.subscribe(
      x => (this.shipsBySeverityTotalCount = x.totalCount)
    );

    this.dataExists$ = this.shipsBySeverityLevel$.pipe(
      map(shipsBySeverity => shipsBySeverity.totalCount > 0)
    );

    this.shipsBySeverityLevelView$ = this.shipsBySeverityLevel$.pipe(
      map(shipsBySeverityLevel => [
        {
          name: 'low',
          value: shipsBySeverityLevel.countOfLowSeverityShips,
        },
        {
          name: 'moderate',
          value: shipsBySeverityLevel.countOfModerateSeverityShips,
        },
        {
          name: 'good',
          value: shipsBySeverityLevel.countOfGoodSeverityShips,
        },
        {
          name: 'excellent',
          value: shipsBySeverityLevel.countOfExcellentSeverityShips,
        },
      ])
    );
  }
}
