import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  ScoringFactorDtoKey,
  scoringFactorsDisplayOrder,
  ScoringFactorsStats,
  ShipScoringFactor,
} from 'src/app/core/models/ship.model';
import { CoreState } from 'src/app/core/store/state/core.state';
import { stringScore } from 'src/app/shared/models/string-score-widget-model';

@Component({
  selector: 'app-explainable-score',
  templateUrl: './explainable-score.component.html',
  styleUrls: ['./explainable-score.component.scss'],
})
export class ExplainableScoreComponent {
  @Input()
  titleText: string = 'Scoring Factors';
  disclaimer: string = 'Based on last 11 days (excluding today)';

  low: stringScore = {
    title: 'Low',
    barColor: '#F399A9',
    selectedBarColor: '#E73252',
    indecies: [1, 2, 3, 4, 5, 6],
  };
  moderate: stringScore = {
    title: 'Moderate',
    barColor: '#FFE18D',
    selectedBarColor: '#FFC21B',
    indecies: [7],
  };
  good: stringScore = {
    title: 'Good',
    barColor: '#B0D295',
    selectedBarColor: '#B0D295',

    indecies: [8, 9],
  };
  excellent: stringScore = {
    title: 'Excellent',
    barColor: '#B0D295',
    selectedBarColor: '#61A42B',
    indecies: [10],
  };
  scores: stringScore[] = [this.low, this.moderate, this.good, this.excellent];

  @Input()
  score: number = 0;

  @Input()
  scoreExplainationDisplayOrder: ScoringFactorDtoKey[] =
    scoringFactorsDisplayOrder;

  @Input()
  scoreExplainations$!: Observable<ScoringFactorsStats | null>;

  @Input()
  showDisclaimer: boolean = false;

  @Input()
  textExplaination: string | null = null;

  constructor(private store: Store<CoreState>) {}

  sortScoreExplaination(
    scoringFactors: ScoringFactorsStats
  ): ShipScoringFactor[] {
    const orderedShipScoringFactors: ShipScoringFactor[] = [];
    this.scoreExplainationDisplayOrder.forEach(element =>
      orderedShipScoringFactors.push(scoringFactors[element])
    );
    return orderedShipScoringFactors;
  }

  round(n: number): number {
    return Math.round(n);
  }
}
