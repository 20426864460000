import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChildren,
  EventEmitter,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-carousel',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent {
  @ContentChildren('carouselItem')
  carouselItems!: QueryList<TemplateRef<any>>;

  @Output()
  arrowClicked = new EventEmitter<number>();

  currentIndex: number = 0;

  next(): void {
    this.currentIndex = (this.currentIndex + 1) % this.carouselItems.length;
    this.arrowClicked.emit(this.currentIndex);
  }

  prev(): void {
    this.currentIndex =
      (this.currentIndex - 1 + this.carouselItems.length) %
      this.carouselItems.length;
    this.arrowClicked.emit(this.currentIndex);
  }

  selectIndex(index: number): void {
    this.currentIndex = index;
  }
}
