import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  of,
  startWith,
  Subject,
  Subscription,
  switchMap,
  takeUntil,
} from 'rxjs';
import { ModuleFeatureExpiredDialogComponent } from 'src/app/core/components/dialogs/module-feature-expired-dialog/module-feature-expired-dialog.component';
import { ModuleWillComeSoonDialogComponent } from 'src/app/core/components/dialogs/module-will-come-soon-dialog/module-will-come-soon-dialog.component';
import { FeatureFlagState } from 'src/app/core/models/feature-flag.model';
import { MapTooltip, ShipPosition } from 'src/app/core/models/map.models';
import { Ship, ShipSailData } from 'src/app/core/models/ship.model';
import { FeatureFlagService } from 'src/app/core/services/feature-toggle.service';
import { FleetService } from 'src/app/core/services/fleet.service';
import { CoreState } from 'src/app/core/store/state/core.state';
import {
  AuthenticationSelectors,
  EventsActions,
  EventsSelectors,
  FiltersSelectors,
  ShipsActions,
  ShipsSelectors,
} from 'src/app/core/store/types';
import { mapSelectedShipToMapEntity } from 'src/app/core/utils/ship';
import {
  Events,
  ShipLastLocation,
} from 'src/app/core/view-models/event.view.model';
import { PresetFilter } from 'src/app/core/view-models/filter.view.model';
import { Screenshot } from 'src/app/core/view-models/gallery.view.model';
import {
  EventMapEntity,
  ShipMapEntity,
} from 'src/app/mapbox/models/mapbox.models';
import { DateFilterKeys } from 'src/app/shared/view-models/dates.view.model';
import { RoutingService } from '../../../services/routing.service';

@Component({
  selector: 'app-ship',
  templateUrl: './ship.component.html',
  styleUrls: ['./ship.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in'),
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(-100%)' })),
      ]),
    ]),
  ],
})
export class ShipComponent implements OnInit, OnDestroy {
  // Constants
  ONE_HOUR_IN_MILLISECONDS = 1000 * 60 * 60;
  CONNECTION_WITHIN_HOURS = 4;
  // Props
  selectedShipEntities$ = new BehaviorSubject<ShipMapEntity[]>([]);
  shipEvents$ = new BehaviorSubject<EventMapEntity[]>([]);
  selectedPoisition$: BehaviorSubject<ShipPosition | undefined> =
    new BehaviorSubject<ShipPosition | undefined>(undefined);

  ship!: Ship;
  selectedEvent$!: Observable<Events | null>;
  sailDataPeriodFilter = new FormControl<number>(90); //TODO: change this to const value
  generalSailData$!: Observable<ShipSailData | undefined>;
  generalLastMonthEventsCount$!: Observable<number>;
  changeInGeneralEventCount$!: Observable<number>;
  currPeriod$!: Observable<string>;
  congestedSailData$!: Observable<ShipSailData | undefined>;
  openWaterSailData$!: Observable<ShipSailData | undefined>;
  hyperCongestedSailData$!: Observable<ShipSailData | undefined>;
  collisionRiskCongestedSailData$!: Observable<ShipSailData | undefined>;
  collisionRisHyperCongestedSailData$!: Observable<ShipSailData | undefined>;
  collisionRisOpenWaterSailData$!: Observable<ShipSailData | undefined>;
  openWaterLastMonthEventsCount$!: Observable<number>;
  openWaterLastMonthCeEventsCount$!: Observable<number>;
  congestedLastMonthCeEventsCount$!: Observable<number>;
  hyperCongestedLastMonthCeEventsCount$!: Observable<number>;
  shipTrail$ = new BehaviorSubject<ShipLastLocation[] | null>([]);
  changeInOpenWaterEventsCount$!: Observable<number>;
  changeInOpenWaterCeCount$!: Observable<number>;
  changeInCongestedCeCount$!: Observable<number>;
  changeInHyperCongestedCeCount$!: Observable<number>;
  openWaterLastMonthCollisionRiskEventsCount$!: Observable<number>;
  changeInOpenWaterCollisionRiskEventsCount$!: Observable<number>;
  congestedLastMonthEventsCount$!: Observable<number>;
  changeInCongestedEventsCount$!: Observable<number>;
  congestedLastMonthCollisionRiskEventsCount$!: Observable<number>;
  changeInCongestedCollisionRiskEventsCount$!: Observable<number>;
  hyperCongestedLastMonthEventsCount$!: Observable<number>;
  changeInHyperCongestedEventsCount$!: Observable<number>;
  hyperCongestedLastMonthCollisionRiskEventsCount$!: Observable<number>;
  changeInHyperCongestedCollisionRiskEventsCount$!: Observable<number>;
  isShipCaptain$!: Observable<boolean>;
  totalDistanceSailed$!: Observable<number>;
  selectedDateKey$!: Observable<DateFilterKeys>;
  events$!: Observable<Events[]>;
  eventsCount$!: Observable<number>;
  appliedPresetFilter$!: Observable<PresetFilter | undefined>;
  isStatisticsAccordionOpen: boolean = false;
  isLiveStreamOpen: boolean = false;
  prevUrl: string = '';
  selectedTabIndex: number = 0;
  isLivePageEnabled!: boolean;
  startDateAvgShipSafetyScore$!: Observable<number>;
  endDateAvgShipSafetyScore$!: Observable<number>;
  isVisible = false;
  screenshot$!: Observable<Screenshot | null>;
  private destroy$ = new Subject<void>();
  mapTooltip$!: Observable<MapTooltip>;
  private shipEventsSubscription!: Subscription;

  constructor(
    private route: ActivatedRoute,
    private store: Store<CoreState>,
    private routingService: RoutingService,
    private router: Router,
    private location: Location,
    private featureFlag: FeatureFlagService,
    private fleetService: FleetService,
    private dialog: MatDialog
  ) {}
  ngOnInit(): void {
    this.ship = this.route.snapshot.data['ship'];
    this.selectedShipEntities$.next([
      mapSelectedShipToMapEntity(this.ship, true),
    ]);
    this.selectedPoisition$.next({
      long: this.ship.longitude,
      lat: this.ship.latitude,
    });

    this.selectedEvent$ = this.store.select(
      EventsSelectors.selectSelectedEvent
    );

    const livePageFeatureFlagState$ =
      this.featureFlag.getFeatureFlagState$('ff-livepage-v2');

    livePageFeatureFlagState$
      .pipe(takeUntil(this.destroy$))
      .subscribe(state => {
        this.isLivePageEnabled = state === FeatureFlagState.Enabled;
      });

    this.isShipCaptain$ = this.store.select(
      AuthenticationSelectors.selectIsUserShipCaptain
    );

    this.generalSailData$ = this.store.select(
      ShipsSelectors.selectSelectedShipGeneralSailData
    );

    this.congestedSailData$ = this.store.select(
      ShipsSelectors.selectSelectedShipCongestedSailData
    );

    this.hyperCongestedSailData$ = this.store.select(
      ShipsSelectors.selectSelectedShipHyperCongestedSailData
    );

    this.openWaterSailData$ = this.store.select(
      ShipsSelectors.selectSelectedShipOpenWaterSailData
    );

    this.collisionRiskCongestedSailData$ = this.store.select(
      ShipsSelectors.selectSelectedShipCongestedCollisionRiskSailData
    );

    this.collisionRisHyperCongestedSailData$ = this.store.select(
      ShipsSelectors.selectSelectedShipHyperCongestedCollisionRiskSailData
    );

    this.collisionRisOpenWaterSailData$ = this.store.select(
      ShipsSelectors.selectSelectedShipOpenWaterCollisionRiskSailData
    );

    this.generalLastMonthEventsCount$ = this.store.select(
      ShipsSelectors.selectLastMonthsGeneralSailEventCount
    );

    this.changeInGeneralEventCount$ = this.store.select(
      ShipsSelectors.selectChangeInGeneralSailEventCount
    );

    this.openWaterLastMonthEventsCount$ = this.store.select(
      ShipsSelectors.selectLastMonthsOpenWaterSailEventCount
    );

    this.openWaterLastMonthCeEventsCount$ = this.store.select(
      ShipsSelectors.selectLastMonthsOpenWaterCeEventCount
    );

    this.congestedLastMonthCeEventsCount$ = this.store.select(
      ShipsSelectors.selectLastMonthsCongestedCeEventCount
    );

    this.hyperCongestedLastMonthCeEventsCount$ = this.store.select(
      ShipsSelectors.selectLastMonthsHyperCongestedCeEventCount
    );

    this.changeInOpenWaterEventsCount$ = this.store.select(
      ShipsSelectors.selectChangeInOpenWaterSailEventCount
    );

    this.changeInOpenWaterCeCount$ = this.store.select(
      ShipsSelectors.selectChangeInOpenWaterCeEventCount
    );

    this.changeInCongestedCeCount$ = this.store.select(
      ShipsSelectors.selectChangeInCongestedCeEventCount
    );

    this.changeInHyperCongestedCeCount$ = this.store.select(
      ShipsSelectors.selectChangeInHyperCongestedCeEventCount
    );

    this.openWaterLastMonthCollisionRiskEventsCount$ = this.store.select(
      ShipsSelectors.selectLastMonthsOpenWaterCollisionRiskSailEventCount
    );
    this.changeInOpenWaterCollisionRiskEventsCount$ = this.store.select(
      ShipsSelectors.selectChangeInOpenWaterCollisionRiskSailEventCount
    );

    this.congestedLastMonthEventsCount$ = this.store.select(
      ShipsSelectors.selectLastMonthsCongestedSailEventCount
    );
    this.changeInCongestedEventsCount$ = this.store.select(
      ShipsSelectors.selectChangeInCongestedSailEventCount
    );

    this.congestedLastMonthCollisionRiskEventsCount$ = this.store.select(
      ShipsSelectors.selectLastMonthsCongestedCollisionRiskSailEventCount
    );
    this.changeInCongestedCollisionRiskEventsCount$ = this.store.select(
      ShipsSelectors.selectChangeInCongestedCollisionRiskSailEventCount
    );

    this.hyperCongestedLastMonthEventsCount$ = this.store.select(
      ShipsSelectors.selectLastMonthsHyperCongestedSailEventCount
    );
    this.changeInHyperCongestedEventsCount$ = this.store.select(
      ShipsSelectors.selectChangeInHyperCongestedSailEventCount
    );

    this.hyperCongestedLastMonthCollisionRiskEventsCount$ = this.store.select(
      ShipsSelectors.selectLastMonthsHyperCongestedCollisionRiskSailEventCount
    );
    this.changeInHyperCongestedCollisionRiskEventsCount$ = this.store.select(
      ShipsSelectors.selectChangeInHyperCongestedCollisionRiskSailEventCount
    );

    this.totalDistanceSailed$ = this.store.select(
      ShipsSelectors.selectSelectedShipTotalDistanceSailed
    );

    this.selectedDateKey$ = this.store.select(
      FiltersSelectors.selectFilterdDatesKey
    );

    this.prevUrl = this.routingService.getPreviousUrl();

    this.events$ = this.store.select(EventsSelectors.selectShipEvents);

    this.eventsCount$ = this.store.select(
      EventsSelectors.selectShipEventsCount
    );

    this.appliedPresetFilter$ = this.store.select(
      EventsSelectors.selectAppliedPresetInShip
    );

    this.currPeriod$ = this.sailDataPeriodFilter.valueChanges.pipe(
      startWith(this.sailDataPeriodFilter.value),
      map(days => {
        if (days === 90) return 'Last 3 Months';
        else if (days === 180) return 'Last 6 Months';
        else return 'Last Year';
      })
    );

    this.endDateAvgShipSafetyScore$ = this.store.select(
      ShipsSelectors.selectEndDateAvgShipSafetyScore
    );

    this.startDateAvgShipSafetyScore$ = this.store.select(
      ShipsSelectors.selectStartDateAvgShipSafetyScore
    );

    this.screenshot$ = this.store.select(ShipsSelectors.selectShipScreenshot);

    const updateMapTooltip = (
      isLiveStreamOpen: boolean,
      isLivePageEnabled: boolean
    ) => {
      this.mapTooltip$ = this.store
        .select(ShipsSelectors.selectMapTooltip)
        .pipe(
          takeUntil(this.destroy$),
          map(tooltip => ({
            ...tooltip,
            isVisible: isLiveStreamOpen,
            isLiveStreamOpen: isLiveStreamOpen,
            showLiveStreamFlag: isLivePageEnabled,
          }))
        );
    };

    combineLatest([this.route.queryParams, livePageFeatureFlagState$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([params, livePageFeatureFlagState]) => {
        this.isLiveStreamOpen = params['isLiveStreamOpen'] === 'true';
        this.selectedTabIndex = params['tab'] === 'events' ? 1 : 0;
        this.isLivePageEnabled =
          livePageFeatureFlagState === FeatureFlagState.Enabled;
        updateMapTooltip(this.isLiveStreamOpen, this.isLivePageEnabled);

        if (!this.isLiveStreamOpen) {
          return;
        }

        if (livePageFeatureFlagState === FeatureFlagState.Disabled) {
          this.dialog.open(ModuleWillComeSoonDialogComponent, {
            disableClose: true,
          });
        }

        if (livePageFeatureFlagState === FeatureFlagState.Expired) {
          this.dialog.open(ModuleFeatureExpiredDialogComponent, {
            disableClose: true,
          });
        }
      });

    this.initializeMapEventStream();
    this.setSelectedShipOnMap(this.ship.shipId);

    this.fleetService
      .getShipLastLocations$(this.ship.shipId, new Date())
      .then(shipLastLocations => {
        const lastLocationsWithShip = [
          ...shipLastLocations,
          {
            latitude: this.ship.latitude,
            longitude: this.ship.longitude,
            sog: this.ship.sog ?? 0,
            time: new Date().toISOString(),
          },
        ];
        this.shipTrail$.next(lastLocationsWithShip || []);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    // eslint-disable-next-line @ngrx/avoid-dispatching-multiple-actions-sequentially
    this.store.dispatch(ShipsActions.closeShipConnectionStatus());
    // eslint-disable-next-line @ngrx/avoid-dispatching-multiple-actions-sequentially
    this.store.dispatch(
      ShipsActions.closePollingLiveStream({
        shipDialogDef: { streamUrl: '', isStreamMaster: false },
      })
    );
    if (this.shipEventsSubscription) {
      this.shipEventsSubscription.unsubscribe();
    }
  }

  onTabChanged(index: number) {
    const tabName = index === 0 ? 'overview' : 'events';
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { tab: tabName },
      queryParamsHandling: 'merge',
    });
  }

  onFilterChanged(change: PresetFilter | undefined): void {
    this.store.dispatch(
      EventsActions.setPresetFilterInShip({
        selectedPresetFilter: change ? { ...change } : undefined,
        shipName: this.ship.shipName,
      })
    );
  }

  onScroll() {
    this.store.dispatch(
      EventsActions.getMoreShipEventsOnUserScroll({
        shipName: this.ship.shipName,
      })
    );
  }

  onSailDataChange(days: number): void {
    this.store.dispatch(
      ShipsActions.getShipSailData({
        shipId: this.ship.shipId,
        periodInDays: days,
      })
    );
  }

  onSelectedListEvent(event: Events): void {
    this.store.dispatch(EventsActions.setSelectedFilteredEvent({ event }));
  }

  onSelectedMapEntity(entityParams: string[]): void {
    const entityId = entityParams[0];
    const entityCategory = entityParams[1];
    if (entityCategory === 'ship') {
      this.setSelectedShipOnMap(Number(entityId));
      // eslint-disable-next-line @ngrx/avoid-dispatching-multiple-actions-sequentially
      this.store.dispatch(EventsActions.clearSelectedEvent());
      // eslint-disable-next-line @ngrx/avoid-dispatching-multiple-actions-sequentially
      this.store.dispatch(
        ShipsActions.setSelectedShipIdOnShipMapClick({
          shipId: Number(entityId),
        })
      );
    } else {
      // eslint-disable-next-line @ngrx/avoid-dispatching-multiple-actions-sequentially
      this.store.dispatch(
        EventsActions.setSelectedEventsMapEvent({ eventId: entityId })
      );
    }
  }

  setSelectedShipOnMap(shipId: number): void {
    if (this.isLiveStreamOpen) {
      this.store.dispatch(
        ShipsActions.setSelectedShipIdOnTooltipShipProfile({
          shipId: Number(shipId),
        })
      );
    }
  }
  onEventExploration(event: Events): void {
    if (event.isRtEvent) {
      this.router.navigate([
        `/private`,
        `fleet`,
        `rt-event-exploration`,
        event.eventId,
      ]);
    } else {
      this.router.navigate([
        `/private`,
        `fleet`,
        `event-exploration`,
        event.eventId,
      ]);
    }
  }

  isConnectionWithinFourHours(): boolean {
    if (!this.ship.lastConnection) return false;

    const now = new Date();
    const lastConnectionTime = new Date(this.ship.lastConnection).getTime();
    const diffInHours =
      (now.getTime() - lastConnectionTime) / this.ONE_HOUR_IN_MILLISECONDS;
    return diffInHours <= this.CONNECTION_WITHIN_HOURS;
  }

  initializeMapEventStream(): void {
    const shipRealTimeData$ = this.store.select(
      ShipsSelectors.selectShipRealTimeData
    );
    const mapShipEvents$ = this.store.select(
      EventsSelectors.selectMapShipEvents
    );

    this.shipEventsSubscription = combineLatest([
      shipRealTimeData$,
      mapShipEvents$,
    ])
      .pipe(
        switchMap(([shipRealTimeData, mapShipEvents]) => {
          if (shipRealTimeData == null) {
            return of([...mapShipEvents]);
          }

          this.shipEvents$.next(mapShipEvents);

          return mapShipEvents;
        })
      )
      .subscribe();
  }

  navigateBackFromLiveStream() {
    this.location.back();
  }

  updateLiveStreamStatus(status: boolean): void {
    this.isLiveStreamOpen = status;
    this.router.navigate([], {
      queryParams: { isLiveStreamOpen: status ? 'true' : null },
    });
  }

  navigateBack(): void {
    if (this.prevUrl.includes('/live')) {
      this.router.navigateByUrl('/private/live');
    } else {
      this.router.navigateByUrl('/private/fleet');
    }
  }
}
