import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appOnSelected]',
})
export class OnSelectedDirective {
  @Output()
  selected = new EventEmitter<any>();

  @Input()
  key: any;

  @HostListener('click')
  onClick() {
    this.selected.emit(this.key);
  }
}
