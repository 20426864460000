import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-delta-risks',
  templateUrl: './delta-risks.component.html',
  styleUrls: ['./delta-risks.component.scss'],
  imports: [CommonModule],
  standalone: true,
})
export class DeltaRisksComponent {
  @Input()
  currRisk!: number | null;
  @Input()
  oldRisk!: number | null;

  getRoundedDelta() {
    if (!this.currRisk || !this.oldRisk || this.oldRisk === 0) {
      return 0;
    }

    const delta = 100 * ((this.currRisk - this.oldRisk) / this.oldRisk);
    const roundedDelta = Math.round(delta);

    return roundedDelta;
  }

  getDeltaColorClass(): string {
    const roundedDelta = this.getRoundedDelta();

    if (roundedDelta > 0) {
      return 'green';
    } else if (roundedDelta < 0) {
      return 'red';
    } else {
      return 'gray';
    }
  }
  getDeltaAbs() {
    return Math.abs(this.getRoundedDelta());
  }
}
