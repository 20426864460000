import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { BehaviorSubject } from 'rxjs';
import { MapboxMenuOptionGroup } from 'src/app/mapbox/models/mapbox-menu.models';

@Component({
  selector: 'app-layers-menu-form',
  standalone: true,
  imports: [CommonModule, MatCheckboxModule],
  templateUrl: './layers-menu-form.component.html',
  styleUrls: ['./layers-menu-form.component.scss'],
})
export class LayersMenuFormComponent {
  @Input() optionGroups$!: BehaviorSubject<MapboxMenuOptionGroup[]>;
  optionGroups: MapboxMenuOptionGroup[] = [];

  ngOnInit(): void {
    this.optionGroups = this.optionGroups$.getValue();
    this.optionGroups$.subscribe(value => (this.optionGroups = value));
  }

  onSelection(groupId: string, optionId: string) {
    const groups = this.optionGroups$.getValue();
    const option = groups
      .find(group => group.id === groupId)
      ?.options.find(option => option.id === optionId);
    if (!option) {
      console.error(`Could not find option ${optionId} of group ${groupId}`);
      return;
    }
    option!.isChecked = !option.isChecked;
    this.optionGroups$.next(groups);
  }
}
