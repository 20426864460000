import {
  HighDuringLastWeekInsight,
  InsightType,
  ShipWithLeastEventsInsight,
  ShipWithMostEventsInsight,
} from 'src/app/core/models/insights.models';
import { AppliedEventUrlFilter } from 'src/app/core/view-models/filter.view.model';
import { dateFilters } from '../view-models/dates.view.model';

export interface InsightLink {
  text: string;
  linkTo: string;
  filterEventsQueryParams: AppliedEventUrlFilter | null;
}

export const insightTitleByType: Record<InsightType, string> = {
  ShipWithMostEventsInsight: 'Ship with most events per 1000NM',
  ShipWithLeastEventsInsight: 'Ship with fewest events per 1000NM',
  HighDuringLastWeekInsight: 'High Severity Events During Last Week',
};

export const InsightContentTemplates: Record<
  InsightType,
  (content: any) => string
> = {
  HighDuringLastWeekInsight: (content: HighDuringLastWeekInsight) => ` 
    <span>In the last 7 days, your ships had an average of 
      <strong>${content.formattedAvgNumOfHighEventsPerShip} high severity events</strong> 
      per ship, totaling ${content.numOfSeverityEvents} events.
    </span>
    <br />
    <span>For comparison, Orca benchmark indicates 
      <strong>${content.formattedBenchmarkAvgNumOfHighEventsPerShip} high severity events per ship.</strong>
    </span>
  `,
  ShipWithMostEventsInsight: (content: ShipWithMostEventsInsight) => `
    <strong>${content.outsandShipName} had ${content.formattedOutstandShipNumOfEventsPer1000Nm}</strong>
    events per 1000 NM of sailing (${content.formattedOutstandShipTotalNumOfEvents} events in total) during the last completed month,
    which is the highest in your fleet.
    <br />
    On average, your ships had ${content.formattedFleetAvgNumOfEventPer1000Nm} events per every 1000 NM.
  `,
  ShipWithLeastEventsInsight: (content: ShipWithLeastEventsInsight) => `
    <strong>${content.outsandShipName} had ${content.formattedOutstandShipNumOfEventsPer1000Nm}</strong>
    events per 1000 NM of sailing (${content.formattedOutstandShipTotalNumOfEvents} events in total) during the last completed month,
    which is the lowest in your fleet.
    <br />
    On average, your ships had ${content.formattedFleetAvgNumOfEventPer1000Nm} events per every 1000 NM.
  `,
};

export const InsightLinkTemplates: Record<
  InsightType,
  (content: any) => InsightLink
> = {
  HighDuringLastWeekInsight: (content: HighDuringLastWeekInsight) => ({
    text: 'Explore in the Events page',
    linkTo: '/private/events',
    filterEventsQueryParams: {
      severityLevels: ['high'],
      dateFilter: { 'Last 7 days': dateFilters['Last 7 days'] },
    } as AppliedEventUrlFilter,
  }),
  ShipWithMostEventsInsight: (content: ShipWithMostEventsInsight) => ({
    text: 'Explore Ship Events',
    linkTo: '/private/events',
    filterEventsQueryParams: {
      shipName: { value: [content.outsandShipName] },
      dateFilter: { 'Last 30 days': dateFilters['Last 30 days'] },
    } as AppliedEventUrlFilter,
  }),
  ShipWithLeastEventsInsight: (content: ShipWithLeastEventsInsight) => ({
    text: 'Explore Fleet Scores',
    linkTo: '/private/fleet',
    filterEventsQueryParams: null,
  }),
};
