import { Component, Input } from '@angular/core';
import { stringScore } from '../models/string-score-widget-model';

export type stringScores = 'low' | 'moderate' | 'good' | 'excellent';

@Component({
  selector: 'app-string-score-widget',
  templateUrl: './string-score-widget.component.html',
  styleUrls: ['./string-score-widget.component.scss'],
})
export class StringScoreWidgetComponent {
  @Input()
  score: number = 0;

  @Input()
  scoreDetail: stringScore[] = [];

  @Input()
  scores = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  isSelected(index: number): boolean {
    return index == this.score;
  }
}
