import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ExplainableScoreComponent } from '../core/components/modals/explainable-score/explainable-score.component';
import { NewBadgeComponent } from '../core/components/new-badge/new-badge.component';
import { UnderscoreToDisplayedStringPipe } from '../core/pipes/underscore-to-displayed-string.pipe';
import { MaterialModule } from '../material/material.module';
import { BusyIndicatorComponent } from './components/busy-indicator/busy-indicator.component';
import { DeltaRisksComponent } from './components/delta-risks/delta-risks.component';
import { InsightCardComponent } from './components/insight-card/insight-card.component';
import { LikeUnlikeComponent } from './components/like-unlike/like-unlike.component';
import { LiveStreamButtonComponent } from './components/live-stream-button/live-stream-button.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { RangeSliderComponent } from './components/range-slider/range-slider.component';
import { SelectionDropdownComponent } from './components/selection-dropdown/selection-dropdown.component';
import { ColumnComponent } from './components/table/components/column/column.component';
import { DataRowComponent } from './components/table/components/data-row/data-row.component';
import { SortArrowComponent } from './components/table/components/sort-arrow/sort-arrow.component';
import { ScrollToSelectedDirective } from './components/table/directives/scroll-to-selected.directive';
import { TableCellDirective } from './components/table/directives/table-cell.directive';
import { TableHeaderDirective } from './components/table/directives/table-header.directive';
import { TableComponent } from './components/table/table.component';
import { TimeFrameComponent } from './components/time-frame/time-frame.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { DynamicSquareDirective } from './directives/dynamic-square.directive';
import { EventDataDirective } from './directives/event-data.directive';
import { EventMappingDirective } from './directives/event-mapping.directive';
import { EventTypeDirective } from './directives/event-type.directive';
import { InfiniteScrollingDirective } from './directives/infinite-scrolling.directive';
import { OnSelectedDirective } from './directives/on-selected.directive';
import { ElementMappingDirective } from './directives/risk-mapping.directive';
import { TogglePasswordVisibilityDirective } from './directives/toggle-password-visibility.directive';
import { VisibleDirective } from './directives/visible.directive';
import { CapitalizeFirstLetterPipe } from './pipes/capitalize-first-letter.pipe';
import { DateToDayMonthTimePipe } from './pipes/date-to-day-month-time.pipe';
import { DateToDayTimePipe } from './pipes/date-to-day-time.pipe';
import { DateToHoursMinutesAgoPipe } from './pipes/date-to-hours-minutes-ago.pipe';
import { DateToTimeAgoPipe } from './pipes/date-to-time-ago.pipe';
import { DateToTimeComponentPipe } from './pipes/date-to-time-component.pipe';
import { DateToTodayPipe } from './pipes/date-to-today.pipe';
import { NameInitialsPipe } from './pipes/name-initials.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { ScoreToStringLevelPipe } from './pipes/score-to-string-level.pipe';
import { TargetTypeNamePipe } from './pipes/target-type-name.pipe';
import { ToPercentagePipe } from './pipes/to-percentage.pipe';
import { TypeGuardPipe } from './pipes/type-guard.pipe';
import { StringScoreWidgetComponent } from './string-score-widget/string-score-widget.component';

const directives = [
  EventMappingDirective,
  ElementMappingDirective,
  OnSelectedDirective,
  EventTypeDirective,
  EventDataDirective,
  VisibleDirective,
  DynamicSquareDirective,
  InfiniteScrollingDirective,
  TableCellDirective,
  TableHeaderDirective,
  InfiniteScrollingDirective,
  ScrollToSelectedDirective,
];
const components = [
  BusyIndicatorComponent,
  TimeFrameComponent,
  RangeSliderComponent,
  SelectionDropdownComponent,
  NoDataComponent,
  TableComponent,
  ColumnComponent,
  VideoPlayerComponent,
  StringScoreWidgetComponent,
  ExplainableScoreComponent,
];
const standaloneComponents = [
  NewBadgeComponent,
  DeltaRisksComponent,
  LiveStreamButtonComponent,
  LoadingIndicatorComponent,
  LikeUnlikeComponent,
  InsightCardComponent,
];

const pipes = [
  UnderscoreToDisplayedStringPipe,
  NameInitialsPipe,
  DateToDayTimePipe,
  DateToDayMonthTimePipe,
  DateToTimeComponentPipe,
  SafeUrlPipe,
  DateToTodayPipe,
  ScoreToStringLevelPipe,
  DateToTimeAgoPipe,
];

const standalonePipes = [
  ToPercentagePipe,
  DateToHoursMinutesAgoPipe,
  TargetTypeNamePipe,
  TypeGuardPipe,
  CapitalizeFirstLetterPipe,
];

const privates = [DataRowComponent, SortArrowComponent];
const modules = [NgxChartsModule, NgxSliderModule];
@NgModule({
  declarations: [...directives, ...components, ...pipes, ...privates],
  imports: [
    CommonModule,
    MaterialModule,
    ...standaloneComponents,
    ...standalonePipes,
    LetDirective,
    ...modules,
    TogglePasswordVisibilityDirective,
  ],
  exports: [
    TogglePasswordVisibilityDirective,
    ...directives,
    ...components,
    ...standaloneComponents,
    ...pipes,
    ...standalonePipes,
    ...modules,
  ],
})
export class SharedModule {}
