import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appTogglePasswordVisibility]',
  standalone: true,
})
export class TogglePasswordVisibilityDirective {
  private show: boolean = false;

  @Input()
  inputRef!: HTMLInputElement;

  constructor(private el: ElementRef) {}

  @HostListener('click', ['$event.target'])
  onClick(): void {
    this.show = !this.show;
    if (this.show) {
      this.el.nativeElement.innerHTML = 'visibility';
      this.inputRef.type = 'text';
    } else {
      this.el.nativeElement.innerHTML = 'visibility_off';
      this.inputRef.type = 'password';
    }
  }
}
