import { createAction, props } from '@ngrx/store';
import { Events } from 'src/app/core/view-models/event.view.model';
import {
  CommentsUser,
  IComment,
} from 'src/app/shared/view-models/comments.view.model';

export const getUsersOfCurrentThread = createAction(
  '[Event Exploration Page] Get threads users by ID',
  props<{ threadId: string }>()
);

export const getThreadDetailsRequested = createAction(
  '[Event Exploration Page] Get threads users and comments by ID',
  props<{ threadId: string }>()
);

export const getCommentsOfCurrentThread = createAction(
  '[Event Exploration Page] Get comments of current thread by thread ID',
  props<{ threadId: string }>()
);

export const setCommentsOfCurrentThreadInStore = createAction(
  '[Event Exploration Page] Set comments of current thread on store',
  props<{ comments: IComment[] }>()
);

export const setUsersOfCurrentThreadInStore = createAction(
  '[Event Exploration Page] Set users of current thread on store',
  props<{ users: CommentsUser[] }>()
);

export const emptyCurrentThreadInStore = createAction(
  '[Event Exploration Page] Empty users of current thread on store'
);

export const addCommentToThread = createAction(
  '[Event Exploration Page] Add comment to thread',
  props<{
    threadEvent: Events;
    content: string;
    mentions: string[];
  }>()
);

export const editCommentOnThread = createAction(
  '[Event Exploration Page] Edit comment to thread',
  props<{
    threadEvent: Events;
    commentId: string;
    content: string;
  }>()
);

export const deleteCommentOnThread = createAction(
  '[Event Exploration Page] Delete comment to thread',
  props<{ threadEvent: Events; commentId: string }>()
);

export const onCommentNotificationClick = createAction(
  '[Event Exploration Page] On click notification comment and send to mixpanel',
  props<{ threadEvent: Events }>()
);
